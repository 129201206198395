import React from "react";
import withStyles from "react-jss";
const styles = (theme) => ({
  mainContainer: {
    background: theme.colors.neutral.white,
    height: (props) =>
      props.hasToken ? "100vh" : props.isSignup ? "100%" : "100vh",
  },
  "@media only screen and (min-width: 1920px)": {
    mainContainer: {
      height: (props) => props.isSignup && "100vh",
    },
  },
});
const MainContainer = ({ classes, children }) => {
  return <div className={classes.mainContainer}>{children}</div>;
};

export default withStyles(styles)(MainContainer);
