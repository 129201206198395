import React from "react";

import { Button } from "shipnow-mercurio";

const FormButton = ({ onClick, loading, name, disabled, text }) => {
  return (
    <Button
      className="mt-3"
      disabled={disabled}
      fullwidth
      isdefault
      loading={loading}
      name={name}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default FormButton;
