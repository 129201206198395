import React from "react";

import { TextV2 } from "shipnow-mercurio";

const LeadText = ({ text, className }) => {
  return (
    <TextV2 size="title" margin="b-12" className={`mb-0'${className}`}>
      {text}
    </TextV2>
  );
};

export default LeadText;
