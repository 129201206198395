import { Model } from "../lib";
import { computed, action, observable } from "mobx";
import omit from "lodash/omit";

export default class Account extends Model {
  constructor(attributes, store) {
    let defaultAttributes = {
      accepted_tariff: false,
      active: null,
      active_services: [],
      billing_email: null,
      company_name: null,
      deactivate_reason: null,
      doc_number: null,
      doc_type: null,
      email: null,
      flex_service: null,
      hero_image_url: null,
      iibb_url: null,
      image_url: null,
      last_name: null,
      name: null,
      onboarding: {
        accepted_operation_constraints: false,
        avg_variant_type: [],
        industry: null,
        location: null,
        monthly_orders: null,
        valid_location: false,
        variant_avg_height: null,
        variant_avg_length: null,
        variant_avg_width: null,
      },
      phone: null,
      picking_preference_own: null,
      support_type: null,
      warehouse_accounts: [],
      payment_modality: null,
    };

    super(attributes, store, defaultAttributes);
  }
  urlRoot = "/accounts";
  availableWarehouses = null;

  @observable stores;

  @computed
  get webURI() {
    return `app/configuration/accounts/${this.id}`;
  }

  @action
  afterSetData() {
    if (this._store && !this.stores) {
      this.stores = this._store.appStore.pointsOfSale.getAll();
    }
  }

  isWarehouseOwner() {
    return this.isOk() && this.stats.warehouses.with_warehouse_system > 0;
  }

  hasMultipleShipnowWarehouse() {
    return (
      this.isOk() && this.stats.warehouse_accounts.with_warehouse_system > 1
    );
  }

  hasSameDayActive() {
    return this.active_services.includes("same_day");
  }

  showSameDayOptions() {
    return this.flex_service || this.hasSameDayActive();
  }

  @computed
  get storeTypes() {
    if (
      !this.stores ||
      (this.store && !this.store.ok()) ||
      !this._store.appStore.ui.lists.storeTypes
    )
      return [];

    const accountStores = this.stores.toArray().map((s) => s.store_type);
    const storeTypes = this._store.appStore.ui.lists.storeTypes
      .toArray()
      .filter((s) => s.isSupportingPublications);
    let filteredStores = [];

    storeTypes.forEach((s) => {
      if (accountStores.includes(s.name)) {
        filteredStores.push(s);
      }
    });

    return filteredStores;
  }

  @action
  fetchAvailableWarehouses(filters = {}) {
    const seller_id = filters.seller_id || this.id;

    const queryString = new URLSearchParams(
      omit(filters, "seller_id")
    ).toString();

    const uri = `${this.urlRoot}/${seller_id}/warehouse_accounts?${queryString}`;
    return this._store.appStore.coreAPIClient.sendRequest(uri, "GET");
  }

  hasChatSupport() {
    return this.support_type === "chat" && !this.isShipnow();
  }

  isShipnow() {
    return this.id === 1;
  }

  isFullfilment() {
    return this.picking_preference_own === "warehouse";
  }

  isCrossdocking() {
    return this.picking_preference_own === "cross_docking";
  }

  isEmailConfirmed() {
    return !!this.confirmed_at;
  }

  @computed
  get cookedType() {
    if (this.picking_preference_own === "warehouse") {
      return "Fullfilment";
    }

    return "Crossdocking";
  }

  @computed
  get statusText() {
    return this.active ? "Activa" : "Desactivada";
  }

  @computed
  get deactivateText() {
    const reasons = {
      debt: "Por falta de pago",
      inactivity: "Por inactividad",
      others: "Por otra razón",
      waiting_for_onboarding: "Por onboarding",
    };

    return reasons[this.deactivate_reason];
  }

  @computed
  get ownUserActivable() {
    return this.active === false && this.deactivate_reason === "inactivity";
  }

  @action
  activate() {
    if (this.ownUserActivable || this._store.appStore.loggedInUser.isAdmin()) {
      return;
    }

    this.set({ active: true, deactivate_reason: null });
    return this.save();
  }

  @action
  deactivate(reason = "debt") {
    if (!this.active) {
      return;
    }

    this.set({ active: false, deactivate_reason: reason });
    return this.save();
  }

  @computed
  get hasBillingInfo() {
    return (
      this.business_name &&
      this.doc_type &&
      this.doc_number &&
      this.fiscal_category
    );
  }
  //el metodo de mobx se ejecuta me devuelve true y despues vuelve a false
  //por eso tuve que crear uno igual, aunque sea momentaneo.
  billingInfo() {
    return !!this.doc_number && !!this.billing_email && !!this.iibb_url;
  }
  hasStoreType() {
    return this.storeTypes && this.storeTypes.length > 0;
  }

  /* start onboarding methods */
  isOnboardingAboutBuisnessIncomplete() {
    return (
      this.onboarding.industry === null ||
      this.onboarding.monthly_orders === null ||
      (this.isCrossdocking() && this.onboarding.location === null)
    );
  }

  isOnboardingPreferencePickingIncomplete() {
    return this.picking_preference_own === null;
  }

  isOnboardingOperationConstraintsAccepted() {
    return this.onboarding.accepted_operation_constraints === true;
  }

  isOnboardingProductsSizeIncomplete() {
    return (
      this.onboarding.avg_variant_type.length === 0 &&
      !this.onboarding.variant_avg_height &&
      !this.onboarding.variant_avg_width &&
      !this.onboarding.variant_avg_length
    );
  }

  isIncompleteOnboarding() {
    return (
      this.isWaitingOnboarding() &&
      (!this.onboarding ||
        this.isOnboardingPreferencePickingIncomplete() ||
        this.isOnboardingAboutBuisnessIncomplete() ||
        !this.isOnboardingOperationConstraintsAccepted() ||
        this.isOnboardingProductsSizeIncomplete())
    );
  }
  /* end onboarding methods */

  /* start of methods related to deactivation reason */
  isWaitingApproval() {
    return this.deactivate_reason === "waiting_for_approval";
  }

  isWaitingOnboarding() {
    return this.deactivate_reason === "waiting_for_onboarding";
  }

  isDisabledByDebt() {
    return this.deactivate_reason === "debt";
  }
  /* end of methods related to deactivation reason */

  /* start payment methods */
  paymentModality() {
    return this.payment_modality;
  }

  isPrePaid() {
    return this.payment_modality === "prepaid";
  }

  isPostPaid() {
    return this.payment_modality === "postpaid";
  }

  hasNoBalance() {
    return this.balance <= 0;
  }
  hasBalance() {
    return this.balance > 0;
  }
  hasAddUpBalance() {
    return this.isPrePaid() && this.balance >= 0 && this.balance <= 3000;
  }

  isPrepaidWithoutBalance() {
    return this.isPrePaid() && this.isDisabledByDebt() && this.hasNoBalance();
  }

  isPostpaidWithoutBalance() {
    return this.isPostPaid() && this.isDisabledByDebt();
  }

  isAccountWithoutBalance() {
    return this.isPrepaidWithoutBalance() || this.isPostpaidWithoutBalance();
  }

  /* end payment methods */

  @computed
  get statusIcon() {
    return this.active ? { icon: "delivered" } : { icon: "cancelled" };
  }

  @computed
  get statusColor() {
    return this.active ? "black" : "blackDisabled";
  }

  @computed
  get supportTypeChat() {
    return this.support_type === "chat";
  }

  @computed
  get supportTypeEmail() {
    return this.support_type === "email";
  }

  update(params) {
    return this._store.appStore.coreAPIClient.sendRequest(
      `${this.urlRoot}/${this.id}`,
      "PUT",
      params
    );
  }

  isConfig() {
    return this.hero_image_url || this.image_url;
  }
}
