import React from "react";
import { Panel, Flex } from "shipnow-mercurio";
import useMediaQuery from "../../../hooks/useMediaQuery";

const FormContainer = ({ children }) => {
  const isMobile = useMediaQuery("(max-width: 500px)");

  return (
    <Flex>
      <Panel width={isMobile ? "350px" : "619px"}>{children}</Panel>
    </Flex>
  );
};

export default FormContainer;
