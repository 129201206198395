import { Model } from "../lib";
import { action, computed, observable, toJS } from "mobx";

export default class Product extends Model {
  urlRoot = "/products";

  @observable variantsItems = null;
  @observable publications = null;
  @observable stocks = null;

  constructor(attributes, store) {
    let defaultAttributes = {
      title: null,
      external_reference: null,
      external_reference_user: null,
      seller_id: null,
      account: null,
      images: null,
      min_stock_own: null,
      picking_preference_own: null,
      declared_dimensions: {
        weight: 0,
        length: 0,
        width: 0,
        height: 0,
      },
      properties: {},
    };

    super(attributes, store, defaultAttributes);
  }

  @action
  changeAttribute(attrName, value) {
    let attributes = {};
    if (value) {
      attributes = toJS(this.properties);
      attributes[attrName] = value;

      this.properties = observable(attributes);
    } else {
      // this is because core twrow a error when you save some like  color = null
      attributes = toJS(this.properties);
      let newAttributes = {};

      for (const key in attributes) {
        if (key !== attrName) {
          newAttributes[key] = attributes[key];
        }
      }

      this.properties = observable(newAttributes);
    }

    return this;
  }

  @action
  afterSetData() {}

  @computed
  get companyName() {
    return this.account.company_name;
  }

  @computed
  get minStock() {
    return (
      this.min_stock_own ||
      this.min_stock ||
      this.account.min_stock_own ||
      this.account.min_stock
    );
  }

  @computed
  get cookedMinStock() {
    if (this.min_stock_own === 0) {
      return "Sin control de stock";
    }

    if (!this.min_stock_own) {
      return "10 unidades";
    }

    return `${this.min_stock_own} unidades`;
  }

  @computed
  get pickingPreference() {
    return (
      this.picking_preference_own ||
      this.picking_preference ||
      this.account.picking_preference_own ||
      this.account.picking_preference
    );
  }

  @computed
  get cookedPickingPreference() {
    if (this.pickingPreference === "cross_docking") {
      return "El producto se despacha a demanda";
    }

    return "El producto despacha desde shipnow HQ.";
  }

  @computed
  get cookedName() {
    if (!this.title) return "";

    let result = this.title.toLowerCase();

    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  @computed
  get cookedDimentions() {
    const noDimension = 0;
    const height = this.declared_dimensions.height || noDimension;
    const width = this.declared_dimensions.width || noDimension;
    const length = this.declared_dimensions.length || noDimension;

    return ` ${length} x ${width}x ${height} cm`;
  }

  @computed
  get webURI() {
    return `${this._store.baseWebURI}/${this.id}`;
  }

  @computed
  get isSelected() {
    return this._store.appStore.ui.lists.selectedProducts.indexOf(this) !== -1;
  }

  @computed
  get cookedWeight() {
    return `${this.declared_dimensions.weight || 0} gramos`;
  }

  @computed
  get stockStatusColor() {
    const color = {
      Disponible: "success",
      "Stock agotado": "error",
      "Stock bajo": "warning",
      "Sin stock": "error",
    };

    return color[this.stockStatus];
  }

  @computed
  get stockStatus() {
    const stats = this.variants.stats;

    if (stats.total_count === stats.with_stock) {
      return "Disponible";
    }

    if (stats.total_count === stats.without_stock || stats.without_stock > 0) {
      return "Sin stock";
    }

    if (stats.low_stock > 1 && stats.with_stock > 1) {
      return "Stock bajo";
    }

    return "Stock agotado";
  }

  @computed
  get stockResume() {
    const stats = this.variants.stats;
    let count =
      stats.total_count === stats.without_stock
        ? stats.without_stock
        : stats.low_stock + stats.without_stock;

    if (stats.total_count === 0) {
      return "Sin variantes";
    }

    if (
      stats.total_count === stats.without_stock ||
      stats.total_count === count
    ) {
      return "En ninguna de las variantes";
    }

    if (stats.total_count === stats.with_stock) {
      return stats.total_count === 1
        ? `En una variante`
        : `En las ${stats.total_count} variantes`;
    }

    return `En ${count} de las ${stats.total_count} variantes`;
  }

  @action
  fetchVariants() {
    if (this.variantsItems) {
      return this;
    } else {
      this.beginUpdate();
      this._store.appStore.variants
        .search({ product_id: this.id })
        .andThen((res) => {
          this.variantsItems = res.toArray();

          this.endUpdate();
        });
    }
    return this;
  }

  @action
  fetchImages() {
    if (!this.isOk() || this.images) {
      return this;
    } else {
      this.beginUpdate();
      this._store.appStore.products.searchImages(this.id, {}).andThen((res) => {
        let cookedImages = res.toJS().map((i) => {
          if (i.image_url) {
            i.image_url = i.image_url.replace(
              "/rails/",
              "https://api.shipnow.com.ar/rails/"
            );
          }
          return i;
        });

        if (this.images) {
          this.images = [...this.images, ...cookedImages];
        } else {
          this.images = cookedImages;
        }
        this.endUpdate();
      });
    }
    return this;
  }
  @action
  fetchPublications() {
    if (this.publications) {
      return this;
    } else {
      this.beginUpdate();
      this._store.appStore.publications
        .search({ product_id: this.id })
        .andThen((res) => {
          this.publications = res.toArray();

          this.endUpdate();
        });
    }
    return this;
  }

  @action
  fetchStock() {
    if (!this.isOk() || this.stocks) {
      return this;
    } else {
      this.beginUpdate();
      this._store.appStore.products.searchStock(this.id, {}).andThen((res) => {
        let stocks = res.toJS();

        this.stocks = stocks;
        this.endUpdate();
      });
    }
    return this;
  }

  @action
  changeMinStock(minStockCount) {
    if (minStockCount === this.min_stock_own) return;

    this.min_stock_own = minStockCount;
    this.save();
  }

  @action
  addImage(image) {
    if (this.images) {
      this.images.unshift(image);
    } else {
      this.images = [];
      this.images.unshift(image);
    }
    return this;
  }

  @action
  deleteImage(image) {
    const imgs = this.images.filter(
      (i) =>
        (image.id && i.id !== image.id) || i.original_url !== image.original_url
    );

    this.images = imgs;

    this.save();

    return this;
  }

  abilities = {
    variants_edited: (loggedInUser) => true,
    images_edited: (loggedInUser) => true,
    showrooms_edited: (loggedInUser) => true,
    warehouses_edited: (loggedInUser) => true,
    publication_edited: (loggedInUser) => true,
    information_edited: (loggedInUser) => true,
    archived: (loggedInUser) => !this.archived,
    kind_toogled: (loggedInUser) => false,
  };

  getNewVariant() {
    let newVariant = this._store.appStore.variants.getNew();
    let uniqueID = "_" + Math.random().toString(36).substr(2, 9);

    if (!this.isNew) {
      newVariant.changeParent(this.id);
    }

    newVariant["product"] = this.toJS();
    newVariant.seller_id = this.account.id;
    newVariant.title = `${this.title}-${uniqueID}`;

    return newVariant;
  }

  get isProduct() {
    return true;
  }
  get isKit() {
    return false;
  }
  get isVariant() {
    return false;
  }

  isGoodsInItem() {
    return false;
  }

  updateValues(values) {
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        this[key] = values[key];
      }
    }
  }
}
