import React from "react";
import withStyles from "react-jss";
const styles = (theme) => ({
  flexContainer: {
    display: (props) => (props.block ? "block" : "flex"),
    alignItems: (props) => (props.alignItems ? props.alignItems : "center"),
    marginTop: (props) => props.marginTop,
    marginRight: (props) => props.marginRight,
    paddingBottom: (props) => props.paddingBottom,
    justifyContent: (props) =>
      (props.right && "rigth") || (props.left && "left") || "center",
  },
  "@media only screen and (max-width: 700px)": {
    flexContainer: {
      flexDirection: "column",
      marginTop: "10px",
    },
  },
});
const FlexContainer = ({ classes, children }) => {
  return <div className={classes.flexContainer}>{children}</div>;
};

export default withStyles(styles)(FlexContainer);
