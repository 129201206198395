import React, { Component } from "react";
import PropTypes from "prop-types";
import { ErrorContent } from "../";
import withStore from "../../../hocs/withStore";
import { ShipnowLogo } from "../../Session/Components";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null, errorInfo: null };

    if (this.props.loggedInUser) {
      // eslint-disable-next-line no-undef
      Raven.setUserContext({ id: this.props.loggedInUser.id });
    }
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any component and report it to Sentry
    this.setState(
      {
        error: error,
        errorInfo: errorInfo,
      },
      // eslint-disable-next-line no-undef
      () => Raven.captureException(error, { extra: errorInfo })
    );
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <>
          {this.props.showLogo && <ShipnowLogo />}
          <ErrorContent
            error={this.state.error}
            errorStack={this.state.errorInfo.componentStack}
            message="Parece que algo salió mal de nuestro lado y te pedimos perdón. Pronto lo estaremos solucionando."
          />
        </>
      );
    }
    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  showLogo: PropTypes.bool,
};

ErrorBoundary.defaultProps = {
  showLogo: false,
};
export default withStore(ErrorBoundary);
